export enum PriceCategoryFormField {
    Id = 'Id',
    EventId = 'EventId',
    Category = 'Category',
    CategoryAr = 'Category_ar',
    Description = 'Description',
    NoOfPersons = 'No_of_persons',
    RegistrationType = 'RegistrationType',
    GenderType = 'Gender_type',
    StartNumber = 'Start_number',
    EndNumber = 'End_number',
    Utilization = 'Utilization',
    AgeFrom = 'Age_from',
    AgeTo = 'Age_to',
    CategoryId = 'CategoryId',
    DateTo = 'Date_to',
    DateFrom = 'Date_from',
    IsRefundable = 'Is_refundable',
    Refund_price = "Refundable_charge",
    Refund_desc = "Refundable_description",
    IsDelivarable = 'Is_delivarable',
    Delivery_price = "Delivery_charge",
    Delivery_desc = "Delivery_description",
    MinPI = 'MinP_i',
    MinPT = 'MinP_t',
    MaxPI = 'MaxP_i',
    MaxPT = 'MaxP_t',
    PriceIn = 'Price_in',
    PriceTe = 'Price_te',
    PriceType = 'Price_type',
    Status = 'Status',
    IsWaiting = 'IsWaiting',
    TotalWaiting = 'WaitingParticipant',
    RegistStartDate = 'WaitingStartDate',
    RegistEndDate = 'WaitingEndDate',
    IsHiddenFromGroup='IsHiddenFromGroup'
}

export type PriceCategoryFormValues = {
    [PriceCategoryFormField.Id]: string | undefined;
    [PriceCategoryFormField.EventId]: string | undefined;
    [PriceCategoryFormField.Category]: string;
    [PriceCategoryFormField.CategoryAr]: string;
    [PriceCategoryFormField.Description]: string;
    [PriceCategoryFormField.DateFrom]: string;
    [PriceCategoryFormField.DateTo]: string;
    [PriceCategoryFormField.NoOfPersons]: string;
    [PriceCategoryFormField.RegistrationType]: string;
    [PriceCategoryFormField.GenderType]: string;
    [PriceCategoryFormField.StartNumber]: number;
    [PriceCategoryFormField.EndNumber]: number;
    [PriceCategoryFormField.Utilization]: number;
    [PriceCategoryFormField.AgeFrom]: number;
    [PriceCategoryFormField.AgeTo]: number;
    [PriceCategoryFormField.CategoryId]: number;
    [PriceCategoryFormField.IsRefundable]: boolean;
    [PriceCategoryFormField.Refund_price]: number;
    [PriceCategoryFormField.Refund_desc]: string;
    [PriceCategoryFormField.IsDelivarable]: boolean;
    [PriceCategoryFormField.Delivery_price]: number;
    [PriceCategoryFormField.Delivery_desc]: string;
    [PriceCategoryFormField.MinPI]: string;
    [PriceCategoryFormField.MinPT]: string;
    [PriceCategoryFormField.MaxPI]: string;
    [PriceCategoryFormField.MaxPT]: string;
    [PriceCategoryFormField.PriceIn]: string;
    [PriceCategoryFormField.PriceTe]: string;
    [PriceCategoryFormField.PriceType]: string;
    [PriceCategoryFormField.Status]: boolean;
    [PriceCategoryFormField.IsWaiting]: boolean;
    [PriceCategoryFormField.TotalWaiting]: number;
    [PriceCategoryFormField.RegistEndDate]: any;
    [PriceCategoryFormField.RegistStartDate]: any;
    [PriceCategoryFormField.IsHiddenFromGroup]: any;
};

type Item = {
    id: number;
    Text: string;
    // Add more properties as needed
};

export type CurrencyCategory = {
    items: Item[];
};

