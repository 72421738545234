import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, message, Select, Spin } from 'antd'
import { TransectionApiServices } from 'features/transaction_list/api/transection-api.service'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const SelectEventSample = ({ setEventNameSelected, setEventName, isOpen, setEventId, setFullData, link, setLink }: { setEventNameSelected: any; setEventName: any; isOpen: boolean; setEventId: any; setFullData: any, link: string, setLink: any }) => {
    const [uploadForm] = Form.useForm();

    const [event, setEvent] = useState({
        items: []
    })

    const [loading, setLoading] = useState<boolean>(false);


    const getEvent = async () => {
        try {
            let val = "BULK_UPLOAD_EVENTS";
            const apires = await TransectionApiServices().getBulkUploadEventList(`Flag=${val}` as any)
            setEvent(apires as any)
        } catch (error) {
            message.error('')
        }
    }

    useEffect(() => {
        getEvent();
    }, [])

    useEffect(() => {
        if (!isOpen) {
            uploadForm.resetFields();
        }
    }, [isOpen])


    const handleEventNameChange = (value: string, label: any) => {
        uploadForm.setFieldsValue({ event_name: value });
        getExcelSample(value);
        setEventName(label)
    };


    const getExcelSample = async (id: any) => {
        setEventId(id)
        setLoading(true);
        try {
            const response: any = await TransectionApiServices().getSampleExcel(id);

            if (response.excel_url) {
                const excelUrl = response.excel_url;
                setLink(excelUrl);
                setFullData(response.full_data);
            } else {
                message.error('No Excel file URL found in the response');

            }
        } catch (error) {
            message.error('Error downloading Excel file');
        }
        finally {
            setLoading(false);
        }
    };

    const handleDownload = () => {
        uploadForm.validateFields(['event_name']).then(() => {
            setLoading(true);

            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            const form = document.createElement('form');
            form.method = 'GET';
            form.action = link;

            iframe.appendChild(form);
            form.submit();

            iframe.onload = () => {
                document.body.removeChild(iframe);
                message.success('Sample Excel file downloaded');
                setLoading(false);
            };

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        });
    };

    const handleClickBtn = async () => {
        try {
            await uploadForm.validateFields(['event_name']);
            setEventNameSelected(true);
        } catch (error) {
            setEventNameSelected(false);
        }
    };

    return (
        <div className='bulk_upld_wrap'>
            <Form form={uploadForm} className='frm_wrapp' layout='vertical'>

                <div className='not_txt'>
                    <div>
                        <h3><strong>Note-</strong></h3>
                        <p>Please first select event than you will get link for download sample excel file.</p>
                        <p>Only Excel(.xls, .xlsx) is supported</p>
                        <p>The File should contain same column as sample file&apos;s column</p>
                    </div>
                </div>


                <div >
                    <div>
                        <Form.Item
                            label={'Event Name'}
                            name={"event_name"}
                            rules={[
                                {
                                    required: true,
                                    message: "Please Select event",
                                },
                            ]}

                        >
                            <Select
                                showSearch
                                options={event?.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
                                placeholder={"Select Event Name"}
                                onChange={handleEventNameChange}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>

                        {
                            loading ?
                                <Spin />
                                : link ?
                                    <Link to="" onClick={handleDownload} >
                                        {<UploadOutlined />}
                                        <span>Download Sample Excel File</span>
                                    </Link>
                                    : null
                        }

                        <div style={{ marginTop: 30 }}>
                            <Button
                                icon={<UploadOutlined />}
                                type={"primary"}
                                onClick={handleClickBtn}
                            >
                                Upload Bulk
                            </Button>
                        </div>


                    </div>

                </div>

            </Form>
        </div>
    )
}

export default SelectEventSample
