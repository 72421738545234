import {
    DownOutlined,
    IdcardOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Checkbox, DatePicker, Form, Input, message } from 'antd';
import { Select } from 'components';
import { EventsApiService } from 'features/events/api/events-api.service';
import { handleKeyAmount, handleKeyDownThree } from 'features/events/utils';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PriceCategoryFormField } from './types';

export const AddPriceCategoryForm = ({ editId, form }: { editId: any, form: any }) => {
    const { t } = useTranslation();

    const Gender = [
        { value: 1, label: 'Male' },
        { value: 2, label: 'Female' },
        { value: 3, label: 'Both' },
    ];

    const [registrationType, setRegistrationType] = useState({
        items: []
    })


    const getRegistrationType = async () => {
        try {
            const apires = await EventsApiService().sportCategoryId(`Flag=${'RegistrationType'}` as any)
            setRegistrationType(apires as any)
        } catch (error) {
            message.error('')
        }
    }
    const [registrationTypeValue, setRegistrationTypeValue] = useState<number>(editId?.RegistrationType);

    useEffect(() => {
        getRegistrationType();
    }, [])

    const handleRegistrationTypeChange = (value: number) => {
        setRegistrationTypeValue(value);
    };

    useEffect(() => {
        if (editId) {
            setRegistrationTypeValue(editId?.RegistrationType)
        } else {
            setRegistrationTypeValue(0)
        }

    }, [editId])


    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Allow only numbers (0-9) and backspace
        if (!(event.key === 'Backspace' || /[0-9]/.test(event.key))) {
            event.preventDefault();
        }
    };



    const validateMaxAmount = (_: any, value: number) => {
        if (!value) {
            return Promise.resolve();
        }
        const stringValue = String(value);
        const regex = /^\d{0,4}(\.\d{0,3})?$/;

        if (regex.test(stringValue)) {

            return Promise.resolve(); // Valid input
        } else {

            return Promise.reject(new Error('Please enter a valid numeric value with at most four digits and up to three decimal places'));
        }
    };


    const validateAgeTo = (_: any, value: string) => {

        const ageFrom = form.getFieldValue(PriceCategoryFormField.AgeFrom);
        if (value && ageFrom && parseInt(value) <= parseInt(ageFrom)) {

            return Promise.reject(new Error('Age To must be greater than Age From'));
        }

        return Promise.resolve();
    };


    return (
        <FormSectionsSeparator title={""}>
            <>
                {editId && <Form.Item
                    label={"Price Type"}
                // name={PriceCategoryFormField.PriceType}

                >
                    <Input
                        prefix={<UserOutlined className='input-prefix-icon' />}
                        value={editId?.price_type_name ? editId?.price_type_name : "Normal"}
                        disabled
                    />
                </Form.Item>
                }
            </>
            <Form.Item<PriceCategoryFormField>
                label={"Category Title"}
                name={PriceCategoryFormField.Category}

                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Enter Category Title"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>
            <Form.Item<PriceCategoryFormField>
                label={"Category Arabic"}
                name={PriceCategoryFormField.CategoryAr}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Enter Category Arabic"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>

            <Form.Item<PriceCategoryFormField>
                label={"Description"}
                name={PriceCategoryFormField.Description}
            // rules={[
            //     {
            //         required: true,
            //         whitespace: true,
            //     },
            // ]}
            >
                <Input
                    placeholder={"Enter Description"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                />
            </Form.Item>


            {editId?.price_type_name === "Early Bird" && <>

                <Form.Item<PriceCategoryFormField>
                    label={"Date From"}
                    name={PriceCategoryFormField.DateFrom}
                    rules={[
                        {
                            required: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>


                <Form.Item<PriceCategoryFormField>
                    label={"Date To"}
                    name={PriceCategoryFormField.DateTo}
                    rules={[
                        {
                            required: true,
                            message: t('validateMessages.custom.nomsg'),
                        },
                    ]}
                >
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>

            </>}

            <Form.Item<PriceCategoryFormField>
                label={"No. of Persons"}
                name={PriceCategoryFormField.NoOfPersons}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Enter No. of Persons"}
                    pattern="[0-9]*"
                    onKeyDown={handleKeyDown}
                />
            </Form.Item>

            <Form.Item<PriceCategoryFormField>
                label={"Registration"}
                name={PriceCategoryFormField.RegistrationType}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Select
                    options={registrationType?.items.map((res: any) => ({ label: res.Text, value: res.Id }))}
                    placeholder={"Select Registration"}
                    className='has-prefix-icon'
                    onChange={handleRegistrationTypeChange}
                    suffixIcon={
                        <>
                            <IdcardOutlined className='prefix-icon' />
                            <DownOutlined className='suffix-icon' />
                        </>
                    }
                />
            </Form.Item>

            <div className='add_tct'>
                {(registrationTypeValue === 1 || registrationTypeValue === 3) &&
                    <div className='add_tct_wrap'>
                        <div className='add_tct_col'>
                            <Form.Item
                                label="Individual Price"
                                name={PriceCategoryFormField.PriceIn}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                    {
                                        validator: validateMaxAmount
                                    }
                                ]}


                            >
                                <Input
                                    placeholder={"Individual Price"}
                                    onKeyDown={handleKeyAmount}
                                />
                            </Form.Item>
                        </div>
                        <div className='add_tct_col'>
                            <Form.Item
                                label="Indivisual Min Part"
                                name={PriceCategoryFormField.MinPI}

                            >
                                <Input
                                    placeholder={"Individual Min Part"}
                                    readOnly
                                />
                            </Form.Item>
                        </div>
                        <div className='add_tct_col'>
                            <Form.Item
                                label="Individual Max Part"
                                name={PriceCategoryFormField.MaxPI}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Individual Max Part"}
                                    onKeyDown={handleKeyDownThree}
                                />
                            </Form.Item>
                        </div>

                    </div>
                }
            </div>
            <div className='add_tct'>
                {(registrationTypeValue === 2 || registrationTypeValue === 3) &&
                    <div className='add_tct_wrap'>
                        <div className='add_tct_col'>
                            <Form.Item
                                label="Team Amount"
                                name={PriceCategoryFormField.PriceTe}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                    {
                                        validator: validateMaxAmount
                                    }
                                ]}
                            >
                                <Input
                                    placeholder={"Team Amount"}
                                    onKeyDown={handleKeyAmount}
                                />
                            </Form.Item>
                        </div>
                        <div className='add_tct_col'>
                            <Form.Item
                                label="Team Min Part"
                                name={PriceCategoryFormField.MinPT}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Team Min Part"}
                                    onKeyDown={handleKeyDownThree}
                                />
                            </Form.Item>
                        </div>
                        <div className='add_tct_col'>
                            <Form.Item
                                label="Team Max Part"
                                name={PriceCategoryFormField.MaxPT}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Team Max Part"}
                                    onKeyDown={handleKeyDownThree} />

                            </Form.Item>
                        </div>
                    </div>
                }
            </div>


            <Form.Item<PriceCategoryFormField>
                label={"Gender"}
                name={PriceCategoryFormField.GenderType}

            >
                <Select
                    options={Gender}
                    placeholder={"Select Gender"}
                    className='has-prefix-icon'
                    suffixIcon={
                        <>
                            <IdcardOutlined className='prefix-icon' />
                            <DownOutlined className='suffix-icon' />
                        </>
                    }
                />
            </Form.Item>

            <Form.Item
                label="Age From"
                name={PriceCategoryFormField.AgeFrom}
                rules={[
                    { required: false, message: 'Please enter Age From' },
                ]}
            >
                <Input
                    placeholder="Enter Age From"
                    prefix={<UserOutlined className="input-prefix-icon" />}
                    onKeyDown={handleKeyDownThree}
                />
            </Form.Item>

            <Form.Item
                label="Age To"
                name={PriceCategoryFormField.AgeTo}
                dependencies={[PriceCategoryFormField.AgeFrom]}
                rules={[
                    { required: false, message: 'Please enter Age To' },
                    { validator: validateAgeTo },
                ]}
            >
                <Input
                    placeholder="Enter Age To"
                    prefix={<UserOutlined className="input-prefix-icon" />}
                    onKeyDown={handleKeyDownThree}
                />
            </Form.Item>
            <Form.Item<PriceCategoryFormField>
                label={"CategoryId"}
                name={PriceCategoryFormField.CategoryId}
                rules={[
                    {
                        required: true,
                        message: t('validateMessages.custom.nomsg'),
                    },
                ]}
            >
                <Input
                    placeholder={"Enter CategoryId"}
                    prefix={<UserOutlined className='input-prefix-icon' />}
                    onKeyDown={handleKeyDown}
                />
            </Form.Item>

            <Form.Item<PriceCategoryFormField>
                // label={"Is Refundable"}
                name={PriceCategoryFormField.IsRefundable}
                valuePropName="checked"
            >
                <Checkbox>Is Refundable</Checkbox>
            </Form.Item>

            <Form.Item
                noStyle
                dependencies={[PriceCategoryFormField.IsRefundable]}
            >
                {({ getFieldValue }) => {
                    const isRefundable = getFieldValue([PriceCategoryFormField.IsRefundable]);

                    return isRefundable ? (

                        <>
                            <Form.Item
                                name={PriceCategoryFormField.Refund_price}
                                label={"Refund Amount"}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                    {
                                        validator(_, value) {
                                            if (!value) {

                                                return Promise.resolve();
                                            }
                                            const stringValue = String(value);
                                            const parsedValue = stringValue.match(/^\d*\.?\d{0,3}$/);
                                            if (parsedValue) {

                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('Please enter a valid numeric value with at most three decimal places'));
                                        },
                                    },
                                ]}
                            >
                                <Input placeholder="Refund Amount"
                                    onKeyDown={handleKeyAmount}
                                />
                            </Form.Item>
                            <Form.Item
                                name={PriceCategoryFormField.Refund_desc}
                                label={"Refund Description"}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input placeholder="Refund Description " />
                            </Form.Item>
                        </>
                    ) : null;
                }}
            </Form.Item>

            <Form.Item<PriceCategoryFormField>
                // label={"CategoryId"}
                name={PriceCategoryFormField.IsDelivarable}
                valuePropName="checked"

            >
                <Checkbox>Is Delivery</Checkbox>
            </Form.Item>
            <Form.Item
                noStyle
                dependencies={[PriceCategoryFormField.IsDelivarable]}
            >
                {({ getFieldValue }) => {
                    const IsDelivared = getFieldValue([PriceCategoryFormField.IsDelivarable]);

                    return IsDelivared ? (
                        <>
                            <Form.Item
                                name={PriceCategoryFormField.Delivery_price}
                                label={"Delivery Price"}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                    {
                                        validator: validateMaxAmount
                                    }
                                ]}
                            >
                                <Input placeholder="Delivery Price"
                                    onKeyDown={handleKeyAmount}
                                />
                            </Form.Item>
                            <Form.Item
                                name={PriceCategoryFormField.Delivery_desc}
                                label={"Delivery Description"}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Input placeholder="Delivery  Description " />
                            </Form.Item>
                        </>
                    ) : null;
                }}
            </Form.Item>

            <Form.Item<PriceCategoryFormField>
                // label={"CategoryId"}
                name={PriceCategoryFormField.IsWaiting}
                valuePropName="checked"

            >
                <Checkbox>Is Waiting</Checkbox>
            </Form.Item>
            <Form.Item
                noStyle
                dependencies={[PriceCategoryFormField.IsWaiting]}
            >
                {({ getFieldValue }) => {
                    const IsWait = getFieldValue([PriceCategoryFormField.IsWaiting]);

                    return IsWait ? (
                        <>
                            <Form.Item
                                name={PriceCategoryFormField.TotalWaiting}
                                label={"Total Waiting"}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                    {
                                        validator: validateMaxAmount
                                    }
                                ]}
                            >
                                <Input placeholder="Total Waiting"
                                    onKeyDown={handleKeyAmount}
                                />
                            </Form.Item>

                            <Form.Item
                                label={"Waiting Start Date"}
                                name={PriceCategoryFormField.RegistStartDate}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                    // validateDates(form.getFieldValue),
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                label={"Waiting End Date"}
                                name={PriceCategoryFormField.RegistEndDate}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                    // validateDates(form.getFieldValue),
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </>
                    ) : null;
                }}
            </Form.Item>

            <Form.Item<PriceCategoryFormField>
                // label={"CategoryId"}
                name={PriceCategoryFormField.IsHiddenFromGroup}
                valuePropName="checked"

            >
                <Checkbox>Hide From Group Registration</Checkbox>
            </Form.Item>

        </FormSectionsSeparator>
    );
};
