import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Empty,
  Grid,
  message,
  Modal,
  Skeleton,
  Space,
  Switch,
  Table,
  TablePaginationConfig,
  Tooltip,
} from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import dayjs from 'dayjs';
import { EventsFilterFormPaginated } from 'features/events';
import { useEventActiveStatus } from 'features/events/use-cases/event-active-status';
import { useEventDeleteStatus } from 'features/events/use-cases/event-delete-status';
import { formatDateDisplay } from 'features/transaction_list/utills/utiils';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction, useState } from 'react';
import { Event } from 'types/event';

import { AddCouponModal } from './Add-coupon-madal/add-coupon-modal';

const { useBreakpoint } = Grid;

type EventsTableProps = {
  isLoading: boolean;
  data?: any;
  tableParams: EventsFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<EventsFilterFormPaginated>>;
  refetch: () => void;
  couponEditData: any;
  setCouponEditData: any;
};

export const CouponTabel = ({
  data,
  isLoading,
  tableParams,
  updateParams,
  refetch,
  couponEditData,
  setCouponEditData,
}: EventsTableProps) => {
  const screens = useBreakpoint();
  const permissions = usePermissions();
  const [showCouponModal, setShowCouponModal] = useState(false);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    // filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Event> | SorterResult<Event>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
        ? 'name'
        : '-name',
    });
  };

  const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };

  const handleCloseModal = () => {
    setShowCouponModal(false);
    setCouponEditData(undefined);
    document.body.style.overflow = 'visible';
  };

  const handleOpenModal = (record: any) => {
    const updateRecord = {
      ...record,
      start_date: dayjs(record.start_date),
      end_date: dayjs(record.end_date),
    };
    setShowCouponModal(true);
    setCouponEditData(updateRecord);
    document.body.style.overflow = 'hidden';
  };

  const { mutate } = useEventDeleteStatus();

  const handleDelete = (record: any) => {
    const deletedFormData = {
      id: record.id,
      status: false,
      flag: 'COUPONS_DELETE',
    };
    mutate(deletedFormData);
  };
  const { confirm } = Modal;

  const showDeleteConfirm = (event: any, record: any) => {
    event.stopPropagation();
    confirm({
      title: 'Are you sure you want to delete this Coupon?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(record);
      },
    });
  };

  const { mutate: updateStatusMutate, isLoading: statusLoading } =
    useEventActiveStatus();

  const handleUpdateStatus = (id: any, isChecked: any) => {
    const statusFormData = {
      id: id,
      status: isChecked,
      flag: 'COUPONS_SET_STATUS',
    };
    updateStatusMutate(statusFormData);
  };

  const handleDownload = () => {
    const link =
      'https://suffixevnt.blob.core.windows.net/events/Coupon/Coupon_sample_manual.xlsx';
    // setLoading(true);

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    const form = document.createElement('form');
    form.method = 'GET';
    form.action = link;

    iframe.appendChild(form);
    form.submit();

    iframe.onload = () => {
      document.body.removeChild(iframe);
      message.success('Sample Excel file downloaded');
      // setLoading(false);
    };

    // setTimeout(() => {
    //     setLoading(false);
    // }, 1000);
  };

  if (!permissions) return null;

  return (
    <>
      <div className={`cstmrightmdl ${showCouponModal && 'show'}`}>
        <AddCouponModal
          showCouponModal={showCouponModal}
          refetch={refetch}
          onClose={handleCloseModal}
          couponEditData={couponEditData}
        />
      </div>

      <StyledTableWrapper>
        <StyledTableHeader>
          <RecordCountBadge text={'Coupon found'} count={data?.total} />
          <a onClick={handleDownload} className='smpe_exl'>
            {' '}
            <DownloadOutlined />
            Sample Excel File
          </a>
        </StyledTableHeader>
        <Table<Event>
          className='cstltblwthldr'
          locale={{
            emptyText: isLoading ? <Skeleton active={true} /> : <Empty />,
          }}
          loading={
            isLoading && {
              indicator: (
                <div>
                  <TableLoader />
                </div>
              ),
            }
          }
          dataSource={isLoading ? [] : data?.items}
          rowKey='id'
          onChange={handleTableChange}
          pagination={{
            current: tableParams.page,
            defaultPageSize: tableParams.size,
            total: data?.total,
          }}
          scroll={tableWidth}
          // onRow={(record) => {
          //     return {
          //         onClick: () => {
          //             handleOpenModal(record);
          //         },
          //     };
          // }}
        >
          <Column<Event>
            title={'Title'}
            dataIndex={'title'}
            // sorter={true}
            // sortDirections={['ascend', 'descend', 'ascend']}
            // defaultSortOrder={'descend'}
            width={screens.xxl ? 'auto' : 200}
            // render={(record: any) => (
            //     <div>
            //         <RightOutlined />
            //         {record?.title}
            //     </div>
            // )}
            // onCell={(record: any) => ({
            //     onClick: () => {
            //         handleOpenModal(record);
            //     },
            // })}
          />

          <Column<any>
            title={'Code'}
            dataIndex={'coupon_code'}
            width={screens.xxl ? 'auto' : 120}
          />
          <Column<any>
            title={'Start Date'}
            // dataIndex={'start_date'}
            width={screens.xxl ? 'auto' : 120}
            render={(_, record) =>
              record?.start_date && formatDateDisplay(record?.start_date)
            }
          />

          <Column<any>
            title={'End Date'}
            render={(_, record) =>
              record?.end_date && formatDateDisplay(record?.end_date)
            }
            width={screens.xxl ? 'auto' : 120}
          />

          <Column<any>
            title={'Value Type'}
            render={(_, record) =>
              record?.value_type === 1 ? 'Flat' : 'Percent'
            }
            width={screens.xxl ? 'auto' : 120}
          />

          <Column<any>
            title={'Value'}
            dataIndex={'value'}
            width={screens.xxl ? 'auto' : 120}
          />

          <Column<any>
            title={'Remaining Limit'}
            dataIndex={'limit'}
            width={screens.xxl ? 'auto' : 120}
          />

          <Column<any>
            title={'Total Limit'}
            dataIndex={'total_limit'}
            width={screens.xxl ? 'auto' : 120}
          />

          <Column<any>
            title={'Status'}
            dataIndex={'coupan_status'}
            width={screens.xxl ? 'auto' : 120}
          />

          {/* <Column<any>
                        title={'No of Person'}
                        dataIndex={'participant_id'}
                        width={screens.xxl ? 'auto' : 120}

                    /> */}

          <Column<Event>
            title={'Action'}
            width={screens.xxl ? 'auto' : 162}
            dataIndex={'is_active'}
            render={(_, record) => (
              <>
                <Space>
                  <Tooltip title='Edit'>
                    <Button
                      shape='circle'
                      icon={<EditOutlined />}
                      onClick={() => handleOpenModal(record)}
                    />
                  </Tooltip>

                  <Tooltip title='Delete'>
                    <Button
                      shape='circle'
                      icon={<DeleteOutlined />}
                      onClick={(event) => showDeleteConfirm(event, record)}
                    />
                  </Tooltip>
                  <Tooltip title='Is Active'>
                    <Switch
                      defaultChecked={record.is_active}
                      checked={record.Status}
                      loading={statusLoading}
                      onChange={(isChecked) =>
                        handleUpdateStatus(record.id, isChecked)
                      }
                    />
                  </Tooltip>
                </Space>
              </>
            )}
          />
        </Table>
      </StyledTableWrapper>
    </>
  );
};
